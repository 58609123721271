<template>
  <div style="background: rgb(248, 248, 248)" class="center">
    <div class="flex listBox">
      <div class="left">
        <div
          class="tabItem"
          :class="{ 'tabItem-select': Selectitem == index }"
          @click="tabs(item, index)"
          v-for="(item, index) in tablist"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="right">
        <div class="between right-title">
          <div style="font-size: 20px; transform: translateY(10px)">
            <div
              v-show="Selectitem == index"
              v-for="(item, index) in tablist"
              :key="index"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="align-center">
            <el-input
              v-model="bianhaoModel"
              size="small"
              placeholder="挂牌编号"
            ></el-input>
            <el-input
              v-model="minghchengModel"
              size="small"
              placeholder="挂牌名称"
              style="margin-left: 10px; margin-right: 10px"
            ></el-input>
            <div class="table-Send" @click="Send">查询</div>
          </div>
        </div>
        <div class="tablebox">
          <el-table :data="tabeldate" :stripe="true" :fit="true">
            <el-table-column label="挂牌编号" prop="code" width="100">
            </el-table-column>
            <el-table-column
              label="挂牌名称"
              align="center"
              prop="title"
              width="160"
            >
            </el-table-column>
            <el-table-column
              label="委托方"
              align="center"
              prop="prower"
              width="200"
            >
              <template v-slot:default="slotProps">
                <span v-html="slotProps.row.prower"></span>
              </template>
            </el-table-column>
            <el-table-column label="变更" prop="is_bg">
              <template v-slot:default="slotProps">
                <span v-if="slotProps.row.is_bg == 0"> 无 </span>
                <span v-if="slotProps.row.is_bg == 1"> 有 </span>
              </template>
            </el-table-column>
            <el-table-column label="公布时间" prop="star_time" min-width="200">
            </el-table-column>
            <el-table-column label="截止时间" prop="end_time" min-width="200">
            </el-table-column>
            <el-table-column label="挂牌底价" prop="price" min-width="150">
              <template v-slot:default="slotProps">
                <span style="color: rgb(232, 116, 58)">
                  {{ slotProps.row.price }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <template v-slot:default="slotProps">
                <span v-if="slotProps.row.status == 0"> 未开始 </span>
                <span v-if="slotProps.row.status == 1"> 公示中 </span>
                <span v-if="slotProps.row.status == 2"> 已结束 </span>
                <span v-if="slotProps.row.status == 3"> 已成交 </span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template v-slot:default="slotProps">
                <div @click="detail(slotProps.row)">查看详情</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    const { data } = await this.$api({
      c: "acticle",
      a: "lists_index",
    });
    this.tablist = data.cate;
    this.tabeldate = data.arr;
    this.Selectitem = this.$route.query.key;
  },
  data() {
    return {
      tablist: [],
      bianhaoModel: "",
      minghchengModel: "",
      Selectitem: 0,
      tabeldate: [],
    };
  },
  methods: {
    async Send() {
      const datas = {
        c: "acticle",
        a: "lists_index",
        cate_id: this.tablist[this.Selectitem].id,
      };
      if (this.bianhaoModel.length > 0) {
        datas.code = this.bianhaoModel;
      }
      if (this.minghchengModel.length > 0) {
        datas.search_title = this.minghchengModel;
      }
      const { data } = await this.$api(datas);
      this.tabeldate = data.arr;
    },
    detail(e) {
      this.$router.push({
        path: "/index/informationdetali",
        query: {
          id: e.id,
        },
      });
    },
    async tabs(item, e) {
      const { data } = await this.$api({
        c: "acticle",
        a: "lists_index",
        cate_id: item.id,
      });
      this.Selectitem = e;
      this.tablist = data.cate;
      this.tabeldate = data.arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.listBox {
  padding-top: 30px;

  .left {
    background: #ffffff;
    height: 560px;
    margin-right: 30px;

    .tabItem {
      padding: 14px 100px 14px 50px;
      border-bottom: 1px solid #ffffff;
      transition: all 0.5s;

      &:hover {
        border-bottom: 1px solid #dfdfdf;
      }
    }

    .tabItem-select {
      border-bottom: 1px solid #dfdfdf;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 20px;
        background: rgb(0, 118, 254);
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .right {
    background: #ffffff;
    padding: 10px 33px 800px;

    .right-title {
      padding-bottom: 13px;
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 23px;
    }
  }

  .tablebox {
    border: 1px solid #dfdfdf;
    border-bottom: none;
  }
}

.table-Send {
  padding: 5px 14px;
  background: rgb(0, 118, 254);
  border-radius: 4px;
  color: #ffffff;
}
</style>
